import React, { Component } from "react";
import "./css/app.css";

import logoImg from "./assets/svg/logo.svg";
import texasImg from "./assets/svg/texas.svg";
import textImg from "./assets/svg/text.svg";
import partnerImg from "./assets/png/partnerImage.png";

class App extends Component {
    state = {
        data: null,
    };

    componentDidMount() {
        document.addEventListener("contextmenu", (e) => {
            e.preventDefault();
        });

        this.callBackendAPI()
            .then((res) => this.setState({ data: res.express }))
            .catch((err) => console.log(err));
    }

    callBackendAPI = async () => {
        const response = await fetch("/express");
        const body = await response.json();

        if (response.status !== 200) {
            throw Error(body.message);
        }

        return body;
    };

    contactButton() {
        window.open("mailto:aw883r@att.com?subject=Contacting via website");
    }

    mapButton() {
        //window.open("maps://maps.google.com/maps?daddr=29.581781,-98.5080304&amp;ll=")
        window.open(
            "https://www.google.com/maps/place/PhaseLink+Utility+Solutions/@29.5815041,-98.5071275,19z/data=!4m5!3m4!1s0x865c93b2beacfe2f:0x3ad48f7422c6951d!8m2!3d29.5817824!4d-98.5058345"
        );
    }

    getYear() {
        return new Date().getFullYear();
    }

    render() {
        return (
            <div className="container">
                <div className="headerContainer">
                    <img className="logoImage" src={logoImg} draggable={false} alt="logo" />
                    <p className="headerTitle">AWE, LLC</p>
                    <p className="headerSubtitle">Copyright © {this.getYear()}</p>
                </div>
                <div className="bodyContainer">
                    <div className="leftContainer">
                        <img className="texasImage" src={texasImg} draggable={false} alt="texas" />
                    </div>
                    <div className="rightContainer">
                        <div className="rightContents">
                            <img className="textImage" src={textImg} draggable={false} alt="text" />
                            <img className="partnerImage" src={partnerImg} draggable={false} alt="partners" />
                        </div>
                    </div>
                </div>
                <div className="footerContainer">
                    <div className="buttons">
                        <button className="bottomButton" onClick={() => this.contactButton()}>
                            Send us an email
                        </button>
                        <div className="spacer"></div>
                        {/**<button className="bottomButton" onClick={() => this.mapButton()}>
                            View us on a map
        </button>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
